import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PremiseHazardType } from '../../premise-hazards/premise-hazard-type.model';
import { Observable, ReplaySubject, Subscription, map, take } from 'rxjs';
import { DataTableComponent } from '@msi/cobalt';
import { PremiseHazardQueryRestService } from '../../services/premise-hazard-rest.service';
import { GetPremiseHazardTypesResponse } from '../../services/get-premise-hazard-types-response.model';
import { GetPremiseHazardTypesRequestModel } from '../../premise-hazards/get-premise-hazard-type-request.model';

@Component({
  selector: 'app-premise-hazard-type-list',
  templateUrl: './premise-hazard-type-list.component.html',
  styleUrls: ['./premise-hazard-type-list.component.scss']
})
export class PremiseHazardTypeListComponent implements OnInit, OnDestroy, OnChanges {
  @Output() PremiseHazardTypeClicked: EventEmitter<PremiseHazardType> = new EventEmitter<PremiseHazardType>();
  @Output() AddNewPremiseHazardTypeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() premiseHazardTypeDeletedSubject: Observable<PremiseHazardType>;
  @Input() changeSelectedPremiseHazardType: Observable<PremiseHazardType>;
  @Input() refreshList: Observable<void>;
  @Input() inEditMode: boolean = false;

  @ViewChild('table') table!: DataTableComponent;

  private changeSelectedPremiseHazardTypeSubscription: Subscription;
  private premiseHazardTypeDeletedSubjectSubscription: Subscription;
  private refreshListSubscription: Subscription;
  private premiseHazardTypes: PremiseHazardType[] = new Array<PremiseHazardType>();
  private premiseHazardTypesMap: Map<string, PremiseHazardType> = new Map<string, PremiseHazardType>();
  private premiseHazardTypesSubject = new ReplaySubject<PremiseHazardType[]>();
  PremiseHazardTypes$: Observable<PremiseHazardType[]> = this.premiseHazardTypesSubject.pipe(map(response => response));
  SelectionIndex: number | null;
  ItemsPerPageValues: number[] = [10, 25, 50, 100];
  ItemsPerPage: number = this.ItemsPerPageValues[0];
  AllowInteraction: boolean = false;
  SelectedPremiseHazardType: PremiseHazardType = null;
  private continuationToken: string = null;
  HasMoreResults: boolean = false;
  IsInitialized: boolean = false;

  constructor(private restService: PremiseHazardQueryRestService) {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngOnDestroy(): void {
    this.changeSelectedPremiseHazardTypeSubscription.unsubscribe();
    this.premiseHazardTypesMap.clear();
    this.premiseHazardTypeDeletedSubjectSubscription.unsubscribe();
    this.refreshListSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.changeSelectedPremiseHazardTypeSubscription = this.changeSelectedPremiseHazardType
      .subscribe(hazardTypeIn => {
        this.onChangeSelectedPremiseHazardType(hazardTypeIn);
      });
    this.premiseHazardTypeDeletedSubjectSubscription = this.premiseHazardTypeDeletedSubject
      .subscribe(hazardType => {
        this.onPremiseHazardTypeDeleted(hazardType);
      });
    this.refreshListSubscription = this.refreshList
      .subscribe(() => {
        this.onRefreshClicked();
      });
    this.loadPremiseHazardTypes().pipe(take(1)).subscribe(response => {
      for (let premiseHazardType of response.premiseHazardTypes) {
        this.premiseHazardTypesMap.set(premiseHazardType.id, premiseHazardType);
      }
      this.premiseHazardTypes.push(...response.premiseHazardTypes);
      this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
      this.continuationToken = response.continuationToken;
      this.AllowInteraction = true;
      this.IsInitialized = true;
      this.HasMoreResults = (this.continuationToken !== null);
      if (this.premiseHazardTypes.length !== 0) {
        this.premiseHazardTypeClicked({ index: 0 });
      }
    });
  }

  private onPremiseHazardTypeDeleted(premiseHazardType: PremiseHazardType): void {
    if (!this.premiseHazardTypesMap.has(premiseHazardType.id)) {
      return;
    }
    let idx = 0;
    for (const [key, _] of this.premiseHazardTypesMap) {
      if (key === premiseHazardType.id) {
        this.premiseHazardTypesMap.delete(key);
        this.premiseHazardTypes.splice(idx, 1);
        this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
        break;
      }
      idx++;
    }
    if (this.premiseHazardTypes.length !== 0) {
      this.PremiseHazardTypeClicked.emit(this.premiseHazardTypes[0]);
    }
  }

  private onRefreshClicked(): void {
    this.AllowInteraction = false;
    if (this.SelectedPremiseHazardType) {
      this.SelectedPremiseHazardType = null;
    }
    this.premiseHazardTypes = new Array<PremiseHazardType>();
    this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
    this.continuationToken = null;
    this.HasMoreResults = false;

    this.loadPremiseHazardTypes().pipe(take(1)).subscribe(response => {
    });
  }

  public premiseHazardTypeClicked(event: any): void {
    let index = event.index;

    if (index < 0 || index >= this.premiseHazardTypes.length) {
      return;
    }
    this.SelectionIndex = index;
    this.PremiseHazardTypeClicked.emit(this.premiseHazardTypes[index]);
  }

  private onChangeSelectedPremiseHazardType(premiseHazardType: PremiseHazardType): void {
    if (!premiseHazardType) {
      this.premiseHazardTypeClicked({ index: this.SelectionIndex });
      return;
    }

    if (!this.premiseHazardTypesMap.has(premiseHazardType.id)) {
      this.premiseHazardTypesMap.set(premiseHazardType.id, premiseHazardType);
      this.premiseHazardTypes.push(premiseHazardType);
      this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
      this.premiseHazardTypeClicked({ index: this.premiseHazardTypes.length - 1 });
    } else {
      let idx = 0;
      for (const [key, _] of this.premiseHazardTypesMap) {
        if (key === premiseHazardType.id) {
          this.AllowInteraction = false;
          this.restService.getPremiseHazardType(key)
            .pipe(take(1))
            .subscribe(response => {
              this.AllowInteraction = true;
              this.premiseHazardTypes[idx] = response.Item;
              this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
            });
          break;
        }
        idx++;
      }
    }
  }

  private loadPremiseHazardTypes(): Observable<GetPremiseHazardTypesResponse> {
    this.AllowInteraction = false;
    let request: GetPremiseHazardTypesRequestModel = {
      continuationToken: this.continuationToken,
      maxItemCount: this.ItemsPerPage,
      includeSharedData: false,
    };
    return this.restService.getPremiseHazardTypes(request);
  }

  public loadNextPageClicked(): void {
    if (!this.HasMoreResults) {
      return;
    }
    this.loadPremiseHazardTypes().pipe(take(1)).subscribe(response => {
      let newTypes = new Array<PremiseHazardType>();
      for (let premiseHazardType of response.premiseHazardTypes) {
        if (!this.premiseHazardTypesMap.has(premiseHazardType.id)) {
          this.premiseHazardTypesMap.set(premiseHazardType.id, premiseHazardType);
          newTypes.push(premiseHazardType);
        }
      }
      this.premiseHazardTypes.push(...newTypes);
      this.premiseHazardTypesSubject.next(this.premiseHazardTypes);
      this.continuationToken = response.continuationToken;
      this.AllowInteraction = true;
      this.IsInitialized = true;
      this.HasMoreResults = (this.continuationToken !== null);
    });
  }

  public setItemsPerPage(index: number): void {
    if (index < 0 || index >= this.ItemsPerPageValues.length) {
      return;
    }
    this.ItemsPerPage = this.ItemsPerPageValues[index];
  }

  public onItemsPerPageChanged(value: string): void {
    let parsedText: number = parseInt(value);
    this.ItemsPerPageValues.forEach((element, index) => {
      if (element === parsedText) {
        this.setItemsPerPage(index);
      }
    });
  }
}
