import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService, ToastService } from '@msi/cobalt';
import { catchError, finalize, of, take } from 'rxjs';
import { AdminRestService } from '../services/admin-rest.service';
import { ResponseItem } from '../services/response-item.model';

@Component({
  selector: 'app-data-sharing',
  templateUrl: './data-sharing.component.html',
  styleUrl: './data-sharing.component.css'
})
export class DataSharingComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('addModal') addCustomerModal: TemplateRef<any>;
  @ViewChild('confirmDeleteModal') confirmDeleteModal: TemplateRef<any>;
  @ViewChild('loadingModal') loadingModal: TemplateRef<any>;

  ngOnInit(): void {
    this.getSharedData();
  }
  ngOnDestroy(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  isAuthorized: boolean = true;
  sharedData: Array<string> = [];
  AddCustomerForm: FormGroup;
  isBusy: boolean = false;
  private etag: string;

  constructor(private adminApi: AdminRestService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private toastService: ToastService,
  ) { }

  private getSharedData(): void {
    this.isBusy = true;
    this.adminApi.getSharingCustomers()
      .pipe(take(1),
        finalize(() => { this.isBusy = false; this.cdr.detectChanges(); }),
        catchError(err => {
          this.isAuthorized = false;
          const overridenResponse: ResponseItem<Array<string>> = {
            Item: null,
            ETag: null,
          }
          return of(overridenResponse);
        }))
      .subscribe((data) => {
        this.sharedData = data.Item;
        this.etag = data.ETag;
      });
  }

  public onRemoveCustomerClicked(index: number): void {
    if (index < 0 || index >= this.sharedData.length) {
      return;
    }
    const customerName = this.sharedData[index];
    const modal = this.modalService
      .open(this.confirmDeleteModal,
        {
          data: { name: customerName },
          hasBackdrop: true,
          disableClose: true
        });

    modal
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }
        this.isBusy = true;
        const customers = [...this.sharedData];
        customers.splice(index, 1);
        const loadingModal = this.modalService.open(this.loadingModal, {
          disableClose: true,
          hasBackdrop: true,
        });
        this.adminApi.updateSharingCustomers(customers, this.etag)
          .pipe(take(1),
            finalize(() => {
              this.isBusy = false;
              loadingModal.close();
              this.getSharedData();
            }))
          .subscribe((result) => {
            this.toastService.success('Sharing customers updated successfully', null, {
              autoDismiss: true,
              closeButton: true,
            });
          });
      });
  }

  public onAddCustomerClicked(): void {
    this.AddCustomerForm = new FormGroup({
      name: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
    });

    const modal = this.modalService.open(this.addCustomerModal, {
      hasBackdrop: true,
      disableClose: true,
    });

    modal.afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result || !this.AddCustomerForm.valid) {
          return;
        }
        const customers = [...this.sharedData];
        customers.push(this.AddCustomerForm.controls['name'].value);
        const loadingModal = this.modalService.open(this.loadingModal, {
          disableClose: true,
          hasBackdrop: true,
        });

        this.isBusy = true;
        this.adminApi.updateSharingCustomers(customers, this.etag)
          .pipe(take(1),
            finalize(() => {
              loadingModal.close();
              this.isBusy = false;
              this.getSharedData();
            }))
          .subscribe((result) => {
            this.toastService.success('Sharing customers updated successfully', null, {
              autoDismiss: true,
              closeButton: true,
            });
          });
      });
  }
}
