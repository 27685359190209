<div class="msi-bg msi-margin-s">
    <div *ngIf="isAuthorized; then listView else errorView"></div>
</div>

<ng-template #errorView>
    <div class="msi-margin-s">
        <msi-callout type="error" title="Unauthorized">
            <p>You are not authorized to view this data.</p>
        </msi-callout>
    </div>
</ng-template>

<ng-template #listView>
    <div *ngIf="sharedData.length === 0">
        <div class="msi-margin-s">
            <msi-callout type="info" title="No Shared Data">
                <div calloutContent>
                    <p>Data is not being shared with any customers</p>
                </div>
            </msi-callout>
        </div>
    </div>
    <div *ngFor="let item of sharedData; let idx = index;">
        <div class="form row-with-cancel-icon msi-padding-s">
            <div>
                <button type="button" class="msi-btn msi-btn-icon" (click)="onRemoveCustomerClicked(idx)"><msi-icon name="ic_remove" [size]="14" class="delete-icon" /></button>
            </div>
            <div>
                {{ sharedData[idx] }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="onAddCustomerClicked()"><msi-icon name="ic_add" />Add Customer</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmDeleteModal let-data let-ref="modalRef">
    <msi-modal [modalRef]="ref">
        <msi-modal-header class="msi-margin-s">
            <label class="msi-label">
                Confirm Removal
            </label>
        </msi-modal-header>
        <msi-modal-content class="msi-margin-m">
            <p>Are you sure you want to remove {{ data.name }} from the sharing list?</p>
        </msi-modal-content>
        <msi-modal-actions align="center" class="msi-margin-s">
            <button class="msi-btn msi-btn-secondary" (click)="ref.close(false)">Cancel</button>
            <button [disabled]="isBusy" class="msi-btn msi-btn-primary" (click)="ref.close(true)">Remove</button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>

<ng-template #addModal let-ref="modalRef" let-form="AddCustomerForm">
    <msi-modal [modalRef]="ref">
        <msi-modal-header class="msi-margin-s">
            <label class="msi-label">
                Add Customer
            </label>
        </msi-modal-header>
        <form [formGroup]="AddCustomerForm" class="msi-margin-m">
            <msi-modal-content class="msi-content-modal-item">
                <label class="msi-label"><msi-icon name="ic_help" msiTooltip="The Customer ID is in the format of xxx.xxx.xxx" size="14" />Enter the customer ID to add to sharing</label>
                <input msiInput class="msi-margin-s" placeholder="example.msi.customer" formControlName="name" />
            </msi-modal-content>
            <msi-modal-actions>
                <div *ngIf="isBusy; then loadingSpinner else buttonRow"></div>
                <ng-template #buttonRow>
                    <div class="form-row msi-padding-s" style="width: 100%;">
                        <div class="form-row-bottom" style="justify-content: space-between;">
                            <div class="col">
                                <button type="button" class="msi-btn msi-btn-text-and-icon" (click)="ref.close(true)"><msi-icon name="ic_add" />Submit</button>
                            </div>
                            <div class="col">
                                <button type="button" [disabled]="!AddCustomerForm.valid" class="msi-btn msi-btn-secondary msi-btn-text-and-icon" (click)="ref.close(false)"><msi-icon name="ic_remove" class="delete-icon" />Cancel</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #loadingSpinner>
                    <div class="row">
                        <div class="col justify-content-center">
                            <msi-spinner size="small" />
                        </div>
                    </div>
                </ng-template>
            </msi-modal-actions>
        </form>
    </msi-modal>
</ng-template>

<ng-template #loadingModal>
    <msi-modal>
        <msi-modal-header>
            Processing
        </msi-modal-header>
        <msi-modal-content>
            <msi-spinner size="large" />Please wait...
        </msi-modal-content>
    </msi-modal>
</ng-template>
